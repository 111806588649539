import 'bootstrap/dist/css/bootstrap.min.css';
import 'notyf/notyf.min.css'; // for React and Vue

import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useParams, NavLink } from 'react-router-dom';
import * as api from './elements/api/api';
import './App.css';
import { useEffect, useState,useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AddClip from './pages/addClip';
import Clips from './pages/clips';
import Notifications from './pages/notifications';
import Playlist from './pages/playlist';
import Player from './pages/player';
import Login from './pages/Login';
import * as cookies from './elements/api/CookieManagement';
import { Notyf } from 'notyf';
import useInterval from './Hooks/useInterval';






function CreateSession() {
  let { uid } = useParams();
  console.log(uid);
  api.createSession(uid, success => {
    if (!success) {
      alert("Session was not found. Maybe it is expired. If you use the remember function a new session with empty queue will started.");
    }
    window.location.href = "/clips";

  });
}

function App() {


  //States

  //General
  const [loggedIn, setLoggedIn] = useState(true);
  //Media
  const [showPlayer, setShowPlayer] = useState(false);
  const [playerSource, setPlayerSource] = useState({});
  const [playerMinimized, setPlayerMinimized] = useState(true);
  const [playlistElements, setPlaylistElements] = useState([]);
  const [playerCallback, setPlayerCallback] = useState([]);
  const [rows, setRows] = useState([]);
  //Mail notifaications
  const [sendMailNotification, setSendMailNotification] = useState(false);
  const [mail, setMail] = useState(cookies.getCookie("mail"));
  const [waitingSource,setWaitingSource] = useState(null);

  //Playlist
  const [selecetedPlaylistIndex, setSelecetedPlaylistIndex] = useState(null);
  const savedCallback = useRef();
  const playNext = () => {
    if (selecetedPlaylistIndex < playlistElements.length - 1) {
      selectPlaylistItem(selecetedPlaylistIndex + 1);
    }
  }
 
  const selectPlaylistItem = (index) => {
    setSelecetedPlaylistIndex(index);
    playMedia(playlistElements[index], () => () => savedCallback.current())
  }
  

  axios.defaults.withCredentials = true;

  useEffect(() => {
    savedCallback.current = playNext;
  }, [selecetedPlaylistIndex, playlistElements]);

  useEffect(
    () => {
      update(true);
    }, []);


  const minimizePlayer = () => {
    setPlayerMinimized(true);
  }







  const validateWaitingWindow = (source) => {
    if(source.status === api.FINISHED){
      setWaitingSource(null);
    }
  }
  const update = (inital = false) => {
    //unsetTicker();
    let pendingClips = rows.filter(row => row.status == api.PENDING).length;
    let notFinishedClips = rows.filter(row => row.status !== api.FINISHED && row.status !== api.PENDING).length;
    if ((pendingClips > 0 || notFinishedClips == 0) && !inital) {
      return;
    }
    api.getJobs(
      json => {
        let r = [];
        for (var k in json) {
          r.push(json[k]);
        }
        setRows([]);
        setRows(r);
        if(waitingSource!=null){
          console.log(waitingSource); 
          validateWaitingWindow(r.filter(row => row.id === waitingSource.id)[0]);
        }
      }
    );
    //setTicker();
  }
  useInterval(update, 5000);


  const playMedia = (source,callback=() => {return; }) => {
    source = rows.filter(row => row.id === source.id)[0];
    if(source.status !== api.FINISHED){
      setWaitingSource(source);
    }
    else{
      setWaitingSource(null);
    }
    document.title = source.title;
    setPlayerSource(source);
    setShowPlayer(true);
    setPlayerMinimized(false);
    setPlayerCallback(callback);
  }

  useEffect(() => {
    api.checkLogin(
      (res) => { setLoggedIn(res.logged_in) }
    )
  }, []);

  const Header = () => {
    return (
      <Navbar expand={loggedIn ? "lg" : undefined} className="bg-body-tertiary">
        <Container>
          <Navbar.Brand >YT-Downloader</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {loggedIn &&
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavLink to="/add-clip" className="nav-link">Add Clip</NavLink>
                <NavLink to="/notfications" className="nav-link">Notifications</NavLink>
                <NavLink to="/clips" className="nav-link">Clips</NavLink>
                <NavLink to="/playlist" className="nav-link">Playlist</NavLink>
                {showPlayer && <Nav.Link onClick={() => setPlayerMinimized(false)} href="javascript:;">Player</Nav.Link>}


                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>  */}
              </Nav>


            </Navbar.Collapse>
          }
        </Container>
      </Navbar>
    );
  }

  return (
    <div className="App">
      
            {showPlayer &&
        <div className={playerMinimized && 'hidden'} >
          { <Player showWaitingWindow={waitingSource!==null} callback={playerCallback} source={playerSource} minimizePlayer={minimizePlayer} setShowPlayer={setShowPlayer} />} 
        </div>
      }
      <Router>
        <Header loggedIn={loggedIn} showPlayer={showPlayer} />
        <Container >
          <Row className="justify-content-md-center">
            <Col className='mt-2'>
              <Routes>
                <Route path='/' element={loggedIn ? <Navigate push to="/add-clip" /> : <Navigate push to="/login" />}></Route>
                <Route path='/login/:uid/' element={<CreateSession />}></Route>
                <Route path='/add-clip' element={loggedIn ? <AddClip sendMailNotification={sendMailNotification} mail={mail} rows={rows} setRows={setRows} /> : <Navigate push to="/" />}></Route>
                <Route path='/clips' element={loggedIn ? <Clips selectPlaylistItem={selectPlaylistItem} playMedia={playMedia} playlistElements={playlistElements} setPlaylistElements={setPlaylistElements} rows={rows} setRows={setRows} /> : <Navigate push to="/" />}></Route>
                <Route path='/notfications' element={loggedIn ? <Notifications setMail={setMail} mail={mail} setSendMailNotification={setSendMailNotification} sendMailNotification={sendMailNotification} /> : <Navigate push to="/" />}></Route>
                <Route path='/playlist' element={loggedIn ? <Playlist selectPlaylistItem={selectPlaylistItem} selecetedIndex={selecetedPlaylistIndex} setSelecetedIndex={setSelecetedPlaylistIndex} playMedia={playMedia} playlistElements={playlistElements} setPlaylistElements={setPlaylistElements} /> : <Navigate push to="/" />}></Route>
                <Route path='/login' element={loggedIn ? <Navigate push to="/" /> : <Login setLoggedIn={setLoggedIn} />}></Route>
              </Routes>
            </Col>
          </Row>
        </Container>
      </Router>

    </div>

  );
}

export default App;
